import type { SelectItem } from '@design/forms/select/select-item/select-item';

export enum LocationType {
  Branch = 'Branch',
  DistributionCenter = 'DistributionCenter',
  Plant = 'Plant',
  Store = 'Store',
  Headquarters = 'Headquarters',
}

export const locationTypeSelectItems: SelectItem<LocationType>[] = [
  {
    id: 'branch',
    title: 'Branch',
    payload: LocationType.Branch,
  },
  {
    id: 'distribution-center',
    title: 'Distribution Center',
    payload: LocationType.DistributionCenter,
  },
  {
    id: 'plant',
    title: 'Plant',
    payload: LocationType.Plant,
  },
  {
    id: 'store',
    title: 'Store',
    payload: LocationType.Store,
  },
  {
    id: 'headquarters',
    title: 'Headquarters',
    payload: LocationType.Headquarters,
  },
];
