import type { DeepPartial } from '@clover/core/helpers/type-helpers';

import { defaultCountry, defaultLocationType, type Address, type Location, type LocationFormValue } from './location';
import { locationTypeSelectItems } from './location-type';

export function mapLocationToFormValue(location: DeepPartial<Location> | undefined): LocationFormValue {
  return {
    name: location?.name ?? '',
    type:
      locationTypeSelectItems.find((item) => item.payload === location?.type) ??
      locationTypeSelectItems.find((item) => item.payload === defaultLocationType),
    globalLocationNumber: location?.globalLocationNumber ?? '',
    address: {
      streetAddressLine1: location?.address.streetAddressLine1 ?? '',
      streetAddressLine2: location?.address.streetAddressLine2 ?? '',
      city: location?.address.city ?? '',
      zipCode: location?.address.zipCode ?? '',
      state: location?.address.state ?? '',
      country: {
        id: location?.address.country ?? defaultCountry,
        title: location?.address.country ?? defaultCountry,
        payload: location?.address.country ?? defaultCountry,
      },
    },
  } as const;
}

export function mapFormValueToLocation(formValue: DeepPartial<LocationFormValue> | undefined): Location {
  return {
    name: formValue?.name ?? '',
    type: formValue?.type.payload ?? defaultLocationType,
    globalLocationNumber: formValue?.globalLocationNumber ?? '',
    address: {
      streetAddressLine1: formValue?.address?.streetAddressLine1 ?? '',
      streetAddressLine2: formValue?.address?.streetAddressLine2 ?? '',
      city: formValue?.address?.city ?? '',
      zipCode: formValue?.address?.zipCode ?? '',
      state: formValue?.address?.state ?? '',
      country: formValue?.address?.country.payload ?? defaultCountry,
    },
  };
}

export function areAddressesEqual(a: Address, b: Address): boolean {
  const keys: Array<keyof Address> = [
    'streetAddressLine1',
    'streetAddressLine2',
    'city',
    'zipCode',
    'state',
    'country',
  ];
  return keys.every((key) => (a[key]?.trim() || undefined) === (b[key]?.trim() || undefined));
}

export function humanizeLocation(address: Address): string {
  if (!address) return '';

  const addressTokens = [
    address.streetAddressLine1,
    address.streetAddressLine2,
    address.city,
    address.zipCode,
    address.state,
    address.country,
  ]
    .map((token) => token?.trim())
    .filter(Boolean);

  return addressTokens.join(', ').trim();
}
